import {NavMenuConfig} from "@/components/navigation/types";
import {useAuthStore} from "@/stores/auth";

const menu: Array<NavMenuConfig> = [
  {
    text: 'Customer',
    items: [
      {icon: 'mdi-view-dashboard-outline', text: 'Dashboard', to: {name: 'customer-dashboard'}},
      {icon: 'mdi-currency-usd', text: 'Performance & Billing', to: {name: 'customer-performance-dashboard'}},
      {icon: 'mdi-chart-line', text: 'Telemetry', to: {name: 'telemetry'}},
      {icon: 'mdi-book-open-outline', text: 'Logbook', to: {name: 'logbook'}},
      {icon: 'mdi-chart-bar-stacked', text: 'Rate Explanation', to: {name: 'rate-explanation'}},
      {icon: 'mdi-cog-outline', text: 'Site Settings', to: {name: 'site-settings'}},
      {icon: 'mdi-help-circle-outline', text: 'FAQ', to: {name: 'support'}},
    ],
  },
  {
    text: 'Utility',
    items: [
      {icon: 'mdi-view-dashboard-outline', text: 'Dashboard', to: {name: 'utility-dashboard'}},
      {icon: 'mdi-file-outline', text: 'FLM2 Tracking & Settlement', to: {name: 'tracking-settlement'}},
      {icon: 'mdi-file-outline', text: 'FLM3 Tracking', to: {name: 'flm3-tracking'}},
      {icon: 'mdi-cash-refund', text: 'Bill Management', to: {name: 'bill-management'}},
      {icon: 'mdi-book-open-outline', text: 'Logbook (all)', to: {name: 'logbook-aggregate'}},
    ],
  },
  {
    text: 'Internal',
    items: [
      {
        icon: 'mdi-toolbox-outline',
        text: 'Admin',
        href: `${process.env.VUE_APP_API_BASE_URL}/admin/`,
        target: '_blank',
        canAccess: () => useAuthStore().roles.viewAdmin,
      },
      {icon: 'mdi-image-search-outline', text: 'Meter Model Analysis', to: {name: 'meter-model-analysis'}},
      {icon: 'mdi-image-search-outline', text: 'System Model Analysis', to: {name: 'system-model-analysis'}},

    ],
  },
];
export default {
  menu,
  footer: [],
};
